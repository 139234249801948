import { createContext } from 'react';

import type { SetStateAction } from 'react';
import type { CollectionItem, Metadata, ResponseFilter, StudentViewStates, Topic } from 'types';

type TopicListContextTypes = {
  setCollectionItems: (collectionItems: SetStateAction<CollectionItem[]>) => void;
  setDiscoveryTotal: (discoveryTotal: SetStateAction<number>) => void;
  setGroupId: (groupId: SetStateAction<number | undefined>) => void;
  setShowBanner: (showBanner: SetStateAction<boolean>) => void;
  setTopics: (topics: SetStateAction<Topic[]>) => void;
  setTopicsMetadata: (metadata: SetStateAction<Metadata | undefined>) => void;
  setTopicFilter?: (filter: SetStateAction<ResponseFilter>) => void;
  collectionItems: CollectionItem[];
  discoveryTotal: number;
  groupId?: number;
  showBanner: boolean;
  studentView?: StudentViewStates;
  topics: Topic[];
  topicsMetadata?: Metadata;
};

const initialTopicListContextValues = {
  setCollectionItems: () => {},
  setDiscoveryTotal: () => {},
  setGroupId: () => {},
  setShowBanner: () => {},
  setTopics: () => {},
  setTopicsMetadata: () => {},
  setTopicFilter: () => {},
  collectionItems: [],
  discoveryTotal: 0,
  groupId: undefined,
  showBanner: true,
  studentView: 'false',
  topics: [],
  topicsMetadata: undefined,
};

export default createContext(initialTopicListContextValues as TopicListContextTypes);
